<template>
    <p class="text-primary text-24 line-height-1 mb-2" v-if="mtdexpenses">{{mtdexpenses}}</p>
    <p class="text-primary text-24 line-height-1 mb-2" v-else><b-spinner variant="primary"></b-spinner></p>
</template>

<script>
    export default {
        data() {
            return {
                mtdexpenses: ''
            }
        },
        mounted() {
            console.log('Month To Date Expenses Mounted');
            fetch( '/bfs-mtdexpenses' )
                .then( res => res.json() )
                .then( data => this.mtdexpenses = '$' + data )
                .catch( err => { 
                    this.mtdexpenses = 'Unsuccessful';
                    console.log( err.message );
                } );

        }
    }
</script>
