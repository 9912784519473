<template>
    <p class="text-primary text-24 line-height-1 mb-2" v-if="mtdorders">{{mtdorders}}</p>
    <p class="text-primary text-24 line-height-1 mb-2" v-else>0</p>
</template>

<script>
    export default {
        data() {
            return {
                mtdorders: ''
            }
        },
        mounted() {
            console.log('Month To Date Orders Mounted');
            fetch( '/bfs-mtdorders' )
                .then( res => res.json() )
                .then( data => this.mtdorders = data )
                .catch( err => { 
                    this.mtdorders = 'Unsuccessful';
                    console.log( err.message );
                } );

        }
    }
</script>
